export default {
  header: "Helping you start the conversation",
  menu: [
    { label: "Home", to: "/" },
    { label: "Youth Conversations", to: "youth-conversations" },
    { label: "Caregiver Conversations", to: "caregiver-conversations" },
    { label: "Foster Conversations", to: "foster-conversations" },
  ],
  caregiver: {
    title: "Caregiver decision\nsupport guide",
    desktop: {
      label: "Download",
      pdf: "/Caregiver_Decision_Support_Guide_Optum.pdf",
    },
    body: [
      {
        subtitle:
          "As a caregiver, it can be daunting to decide how to help your loved one in a time of crisis or a noticeable shift in mood. The decision support guide is a mental health navigation space that reviews options you have, depending on you and your loved one’s needs.  Reference the decision support guide to understand mental health resources that are available to help you connect with the right solution.\n\n Fill in your relevant healthcare contact information, download or print the PDF,  and store or save in a space that is easy to access when you need it quickly.",
      },
    ],
    image: {
      src: require("assets/caregiver.jpg"),
      height: 380,
      width: 379,
    },
  },
  visit: {
    label:
      "See how we’re helping employers, health plans and state governments support mental health and emotional wellbeing.",
    button: {
      label: "Learn more",
      url: "https://business.optum.com/en/health-benefits/behavioral-emotional.html?utm_campaign=OP_83.31_OptumConversationLink_DG_ARic_SCH_2025-00_US&utm_sfgocampaignid=701VV00000MK0IaYAL&utm_medium=DG&utm_source=EWEB&utm_term=2025qvsfm3sh13&s=DG&utm_content=OptumConversationLink",
    },
  },
  footer:
    'If you or someone you know is in crisis— seek safety and get help right away. <strong>If you or someone you know is in immediate danger, call 911 or go to the closest emergency room</strong>.\n\n<strong>To reach a trained crisis counselor, call the 988 Suicide & Crisis Lifeline (previously known as the National Suicide Prevention Lifeline) at 988 or 1-800-273-TALK (1-800-273-8255)</strong>. You may also text 988 or chat at <a href="https://988lifeline.org/?utm_source=optumconversation&utm_medium=weblink&utm_campaign=internal-traffic&utm_id=optumconversation-homepage" target="_blank">988lifeline.org</a>. The lifeline provides 24/7 free and confidential support. *',
  youthSources:
    "\n\nSources\n1. Centers for Disease Control and Prevention. Improving children’s behavioral health. Updated April 19, 2022. Accessed November 7, 2022.\n2. National Alliance on Mental Illness. Mental health by the numbers. Updated June 2022. Accessed November 7, 2022.\n3. Kaiser Family Foundation. Mental health and substance use considerations among children during the COVID-19 pandemic. May 26, 2021. Accessed November 7, 2022.\n4. Verywell Mind. Kid's mental health struggles are affecting parents at work. March 9, 2022. Accessed November 7, 2022.",
  caregiverSources:
    "\n\nSources\n1.	AARP. Valuing the invaluable 2023 update: Strengthening supports for family caregivers. aarp.org. March 8, 2023.\n2.	Centers for Disease Control and Prevention. Supporting caregivers. November 22, 2021.\n3.	Raimondi, Alessandra. A Look At U.S. Caregivers' Mental Health. Washington, DC: AARP Research, August 2023. https://doi.org/10.26419/res.00706.001\n4.	AARP and National Alliance for Caregiving. Caregiving in the U.S. 2020. Washington, DC. May 2020.",
  fosterSources:
    "\n\nSources\n1. Justice Resource Institute, Foster Care: Avoiding Foster Parent Burnout. Accessed October 10, 2024.\n2. American Society for the Positive Care of Children, Foster Care and Adoption. Accessed October 10, 2024.\n3. American Academy of Pediatrics, Mental and Behavioral Health Needs of Children in Foster Care. July 7, 2021.\n4. American Psychological Association, Psychologists work to support children and parents in the child welfare system, but more is needed. March 1, 2023.",
  copyright:
    "\n\nOptum is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other brand or product names are the property of their respective owners. Because we are continuously improving our products and services, Optum reserves the right to change specifications without prior notice. Optum is an equal opportunity employer.\n\n© 2024 Optum, Inc. All rights reserved.",
};
